import React, { Children, lazy, useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useStateContext } from './Contexts/ContextProvider';
import { RequireAuth } from './Contexts/RequiredAuth';
import { FiSettings } from 'react-icons/fi';

import { SignIn, Admin, Error, RedirectPage, Transaction, Products, ProductDetail, ProductsSaved } from './pages'
import { Navbar, Footer, Sidebar, ThemeSettings } from './components';
import "bootstrap/dist/css/bootstrap.min.css";
import './assets/css/utils.css';
import { AuthenticatedRoute } from 'shared/authentication/authentication-route';
import { LayoutPartial } from 'layouts/LayoutPartial';
import NavigationProvider from 'Contexts/NavigationProvider';

const HomePage = lazy(() => import('./pages/HomePage/index'))
const ProductManagement = lazy(() => import('./pages/Products/Management'))
const Employees = lazy(() => import('./pages/Employees/Management'))
const RecProfile = lazy(() => import('./pages/Recruitment/Profile'))
const Map = lazy(() => import('./pages/Map/index'))

// const App = () => {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.tsx</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

function App() {
  const { setCurrentColor, setCurrentMode, currentMode, activeMenu, currentColor, themeSettings, setThemeSettings } = useStateContext();

  useEffect(() => {
    const currentThemeColor = localStorage.getItem('colorMode');
    const currentThemeMode = localStorage.getItem('themeMode');
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, []);

  const routes = () => {
    return <Routes>
      <Route path="/" element={<Navigate to={'/admin/products/home'} replace />} />
      <Route path="/home" element={<HomePage />} />
      <Route path='/admin/sigin' element={<SignIn />} />
      <Route path='/admin/signin-oidc' element={<RedirectPage />} />
      <Route path="/map" element={(<Map />)} />

      <Route element={<RequireAuth allowedRoles={null} />}>
        <Route path="/admin/products/home" element={<Products />} />
        <Route path="/admin/me/products/saved" element={<ProductsSaved />} />
        <Route path="/admin/product/:id" element={(<ProductDetail />)} />

        <Route path="/admin/products" element={(<Products />)} />
        <Route path="/admin/product-detail" element={(<ProductDetail />)} />
        <Route path="/admin/products/management" element={(<ProductManagement key={"admin_product_manager"} />)} />
        <Route path="/admin/myproducts/management" element={(<ProductManagement key={"my_product_manager"} />)} />
        <Route path="/admin/employees/management" element={(<Employees />)} />
        <Route path="/admin/recruitment/profile" element={(<RecProfile />)} />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Route>

    </Routes>
  }

  return (
    <BrowserRouter>
      <NavigationProvider>
        <div className='flex'>
          <LayoutPartial>
            {routes()}
          </LayoutPartial>

        </div>
      </NavigationProvider>
    </BrowserRouter>
  )
}

export default App;
